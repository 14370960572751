<template>
  <span :id="`countup-${idNum}`" :class="['counter-container', props.class]" :style="{ ...props.style }">{{ value }} </span>
  <span v-if="suffix">{{ suffix }}</span>
</template>

<script setup>
import { CountUp } from 'countup.js'
import { numFormat } from '#imports'
const props = defineProps({
  val: {
    type: Number,
    default: 0
  },
  style: {
    type: Object,
    default: () => {}
  },
  class: {
    type: String,
    default: ''
  },
  duration: {
    type: Number,
    default: 60
  },
  decimalPlaces: {
    type: Number,
    default: 0
  },
  delay: {
    type: Number,
    default: 60000
  },
  needFormat: {
    type: Boolean,
    default: true
  },
  useEasing: {
    type: Boolean,
    default: false
  },
  separator: {
    type: String,
    default: ','
  }
})
let idNum = ref(0)
let suffix = ref('')
let value = ref(0)
let str = ''
if (props.needFormat) {
  str = numFormat(props.val) + ''
} else {
  str = props.val + ''
}

const arr = str.match(/\d+(.\d+)?/g)
if (arr.length > 0) {
  value.value = arr[0]
  suffix.value = str.split(arr[0])[1]
} else {
  value.value = props.val
}

// const arr =
let countUp
onMounted(() => {
  idNum.value = Math.floor(Math.random() * 1000000)
  setTimeout(() => {
    countUp = new CountUp(`countup-${idNum.value}`, value.value, {
      startVal: value.value,
      duration: props.duration,
      // suffix: suffix.value,
      decimalPlaces: props.decimalPlaces,
      enableScrollSpy: true,
      scrollSpyDelay: props.delay,
      useEasing: props.useEasing,
      separator: props.separator
    })

    // if (!countUp.error) {
    //   countUp.start()
    // } else {
    //   console.error('abc', countUp.error)
    // }
  }, 10)
})

watch(
  () => props.val,
  (newval, oldval) => {
    let str = ''
    if (props.needFormat) {
      str = numFormat(newval) + ''
    } else {
      str = newval + ''
    }

    const arr = str.match(/\d+(.\d+)?/g)
    suffix.value = str.split(arr[0])[1]
    // console.log('arr', arr, str)
    countUp.update(arr[0])
  }
)
</script>

<style lang="scss" scoped></style>
